
import { Component, Vue, Watch } from 'vue-property-decorator'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import BusinessBranchFilter from 'tradingmate-modules/src/filters/BusinessBranchFilter'
import BusinessCard from '@/components/cards/BusinessCard.vue'
import MemberCard from './MemberCard.vue'
import Paging from '@/components/actions/Paging.vue'
import { SearchBar, SearchBarItem } from '@/components/search'
import Cards from '@/components/cards/Cards.vue'
import InputText from '@/components/inputs/InputText.vue'
import { CategorySelector, ZoneSelector } from '@/components/inputs'
// import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
// import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { BusinessBranchModel, BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchSearchResponse
  from '../../../../../tradingmate-modules/src/models/api/businesses/BusinessBranchSearchResponse'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
// import BusinessBranchOrCategorySearchResult
//   from '../../../../../tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'
import WholeSearchBar from '@/components/search/WholeSearchBar.vue'

@Component({
  components: {
    WholeSearchBar,
    InputCheckbox,
    BusinessCard,
    Paging,
    MemberCard,
    SearchBar,
    SearchBarItem,
    ZoneSelector,
    CategorySelector,
    Cards,
    InputText
  }
})

export default class FindABusiness extends Vue {
  private searchResponse: BusinessBranchSearchResponse | null = null
  private httpError: HttpError | null = null;
  private loading = false;
  private branch: BusinessBranchModel | null = null

  private businessBranchFilter: BusinessBranchFilter = new BusinessBranchFilter({
    OrderByRecent: true,
    Statuses: [BusinessBranchStatus.Active],
    PageSize: 24
  });

  async mounted (): Promise<void> {
    this.branch = this.$store.state.identity.BusinessBranch
    this.search()
  }

  @Watch('$route')
  async search (): Promise<void> {
    this.updateFilter()
    await this.getPagedBusinessBranches()
  }

  // @Watch('$route')
  updateFilter (): void {
    let branchCategories: string[] = []
    let needsMyServices = false
    console.log('route mwml' + this.$route.query.mwl)
    if (this.$route.query.mwl) {
      needsMyServices = true
      if (this.branch) {
        branchCategories = this.branch.Categories.map(x => x.CategoryId)
      }
    }

    const nonProfitSupport = !!this.$route.query.np

    this.businessBranchFilter.CategoryIds = this.$route.query.categories?.toString().split(',')
    this.businessBranchFilter.ZoneIds = this.$route.query.zones?.toString().split(',')
    this.businessBranchFilter.Search = this.$route.query.search?.toString() ?? undefined
    this.businessBranchFilter.Page = this.$route.query.page ? parseInt(this.$route.query.page.toString()) : 1
    this.businessBranchFilter.IsNonProfitCharity = nonProfitSupport
    this.businessBranchFilter.SearchingBranchesCategoryIds = branchCategories
    this.businessBranchFilter.NeedMyServices = needsMyServices
  }

  async getPagedBusinessBranches (): Promise<void> {
    this.loading = true
    await Services.API.Businesses.SearchBranches(this.businessBranchFilter)
      .then((returnBusinesses) => {
        this.searchResponse = returnBusinesses
      })
      .finally(() => { this.loading = false })
  }

  // private handleSearchResponse (): void {
  //   console.log('handling response')
  //   if (this.searchQuery === '' && this.searchResponse?.BusinessSearch != null) {
  //     this.searchQuery = this.searchResponse.BusinessSearch
  //   }
  //
  //   if (!this.zones.length && this.searchResponse?.Zone != null) {
  //     console.log('adding to zones')
  //     this.zones.push(this.searchResponse.Zone)
  //   }
  //
  //   if (!this.categories.length && this.searchResponse?.SearchResult != null) {
  //     this.categories.push(this.searchResponse.SearchResult)
  //   }
  // }

  onPaged (nextPage: number): void {
    this.businessBranchFilter.Page = nextPage
    this.search()
  }
}

