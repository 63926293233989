import {BusinessBranchStatus} from "../models/api/businesses";
import PagedFilterBase from "./PagedFilterBase";

class BusinessBranchFilter extends PagedFilterBase {
    Search?: string;
    ZoneSearch?: string;
    CategoryIds?: string[];
    ZoneIds?: string[];
    Statuses: BusinessBranchStatus[] = [BusinessBranchStatus.Active, BusinessBranchStatus.Inactive];
    IsFranchise?: boolean = false;
    FranchiseId?: string;
    OrderByRecent?: boolean = false;
    UserId?: string;
    IsNonProfitCharity = false;
    SearchingBranchesCategoryIds?: string[];
    NeedMyServices = false

    constructor(init?: Partial<BusinessBranchFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default BusinessBranchFilter
